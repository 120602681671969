import {
  Box,
  Button,
  Card,
  Cell,
  CircularProgressBar,
  Dropdown,
  FormField,
  Input,
  Layout,
  Loader,
  Notification,
  Page,
  SectionHelper,
  SidebarNext,
  SidebarItemNext,
  SidebarDividerNext,
  TagList,
  ToggleSwitch,
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import React, { useEffect } from "react";
import { ReactTagManager } from "react-gtm-ts";

import "./App.css";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  const showNotification = ({
    title,
    type,
  }: {
    title: string;
    type: "success" | "error";
  }) => {
    // You can implement notification logic here
    console.log(`${type}: ${title}`);
  };

  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [options, setOptions] = React.useState({
    // Templates
    template: "popup",
    sidebarAlign: "left",

    // Open Button
    showOpenButton: true,
    buttonTabulationIndex: 0,
    buttonPosition: "bottom-right",
    buttonCaption: "",
    buttonIcon:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.05 349.63"><path d="M95.37,51.29a51.23,51.23,0,1,1,51.29,51.16h-.07A51.19,51.19,0,0,1,95.37,51.29ZM293,134.59A25.61,25.61,0,0,0,265.49,111h-.13l-89.64,8c-3.06.28-6.13.42-9.19.42H126.65q-4.59,0-9.16-.41L27.7,111a25.58,25.58,0,0,0-4.23,51l.22,0,72.45,6.56a8.55,8.55,0,0,1,7.77,8.48v19.62a33.82,33.82,0,0,1-2.36,12.45L60.48,313.66a25.61,25.61,0,1,0,46.85,20.71h0l39.14-95.61L186,334.63A25.61,25.61,0,0,0,232.86,314L191.63,209.14a34.14,34.14,0,0,1-2.35-12.44V177.09a8.55,8.55,0,0,1,7.77-8.49l72.33-6.55A25.61,25.61,0,0,0,293,134.59Z"/></svg>',
    buttonIconPosition: "before",
    buttonEntranceAnimation: "fade",
    buttonHoverAnimation: "none",

    // Popup
    popupOverlay: true,
    popupAnimation: "fade",
    popupScroll: false,
    closeAnywhere: true,
    closeButton: true,
    popupDraggable: true,
    popupShadow: true,

    // Accessibility Profiles
    accessibilityProfiles: true,
    profileEpilepsy: true,
    profileVisuallyImpaired: true,
    profileCognitiveDisability: true,
    profileAdhdFriendly: true,
    profileBlindUsers: true,

    // Online Dictionary
    onlineDictionary: true,
    language: "auto",

    // Readable Experience
    readableExperience: true,
    contentScaling: true,
    textMagnifier: true,
    readableFont: true,
    dyslexiaFont: true,
    highlightTitles: true,
    highlightLinks: true,
    fontSizing: true,
    lineHeight: true,
    letterSpacing: true,
    alignCenter: true,
    alignLeft: true,
    alignRight: true,

    // Visually Pleasing Experience
    visuallyPleasingExperience: true,
    darkContrast: true,
    lightContrast: true,
    monochrome: true,
    highSaturation: true,
    highContrast: true,
    lowSaturation: true,
    textColors: true,
    titleColors: true,
    backgroundColors: true,

    // Easy Orientation
    easyOrientation: true,
    muteSounds: true,
    hideImages: true,
    virtualKeyboard: true,
    readingGuide: true,
    usefulLinks: true,
    stopAnimations: true,
    readingMask: true,
    highlightHover: true,
    highlightFocus: true,
    bigBlackCursor: true,
    bigWhiteCursor: true,
    textToSpeech: true,
    keyboardNavigation: true,

    // Reading Mask
    readingMaskHeight: 100,

    // Virtual Keyboard
    virtualKeyboardLayout: "english",

    // Text to Speech
    ttsPlayIcon:
      '<svg class="selection__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M19.3,10.7L9.1,4.2C8.8,4,8.4,4,8.1,4C7,4,7,4.9,7,5.1v13.4c0,0.2,0,1.1,1.1,1.1c0.3,0,0.7,0,1-0.2l10.2-6.5c0.8-0.5,0.7-1.1,0.7-1.1S20.1,11.2,19.3,10.7z"/></svg>',
    ttsStopIcon:
      '<svg class="selection__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M18.001 4.2H6A1.8 1.8 0 0 0 4.2 5.999V18A1.8 1.8 0 0 0 5.999 19.8H18a1.8 1.8 0 0 0 1.799-1.799V6c0-.992-.807-1.799-1.799-1.799z"/></svg>',
    ttsIconSize: 32,
    ttsLang: "",
    ttsPitch: 1,
    ttsRate: 1,
    ttsVoiceName: "",
    ttsVolume: 1,

    // Footer Options
    resetButton: true,
    hideButton: true,
    accessibilityStatement: true,
    accessibilityStatementType: "iframe",
    accessibilityStatementLink: "./readabler/accessibility-statement.html",
    accessibilityStatementHtml: "",

    // Hot Keys options
    hotKeyOpenInterface: "Alt+9",
    hotKeyMenu: "M",
    hotKeyHeadings: "H",
    hotKeyForms: "F",
    hotKeyButtons: "B",
    hotKeyGraphics: "G",
  });

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  const [selectedSection, setSelectedSection] = React.useState("accessibility");

  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      saveSettings();
    } else {
      setIsLoading(false);
    }
  }, [isEnabled, options]);

  const BASE_URL = `https://certifiedcode.wixsite.com/readable/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsEnabled(data.isEnabled);
        if (data?.options) {
          setOptions({
            // Templates
            template: data.options.template ?? "popup",
            sidebarAlign: data.options.sidebarAlign ?? "left",

            // Open Button
            showOpenButton: data.options.showOpenButton ?? true,
            buttonTabulationIndex: data.options.buttonTabulationIndex ?? 0,
            buttonPosition: data.options.buttonPosition ?? "bottom-right",
            buttonCaption: data.options.buttonCaption ?? "",
            buttonIcon: data.options.buttonIcon ?? defaultOptions.buttonIcon,
            buttonIconPosition: data.options.buttonIconPosition ?? "before",
            buttonEntranceAnimation:
              data.options.buttonEntranceAnimation ?? "fade",
            buttonHoverAnimation: data.options.buttonHoverAnimation ?? "none",

            // Popup
            popupOverlay: data.options.popupOverlay ?? true,
            popupAnimation: data.options.popupAnimation ?? "fade",
            popupScroll: data.options.popupScroll ?? false,
            closeAnywhere: data.options.closeAnywhere ?? true,
            closeButton: data.options.closeButton ?? true,
            popupDraggable: data.options.popupDraggable ?? true,
            popupShadow: data.options.popupShadow ?? true,

            // Accessibility Profiles
            accessibilityProfiles: data.options.accessibilityProfiles ?? true,
            profileEpilepsy: data.options.profileEpilepsy ?? true,
            profileVisuallyImpaired:
              data.options.profileVisuallyImpaired ?? true,
            profileCognitiveDisability:
              data.options.profileCognitiveDisability ?? true,
            profileAdhdFriendly: data.options.profileAdhdFriendly ?? true,
            profileBlindUsers: data.options.profileBlindUsers ?? true,

            // Online Dictionary
            onlineDictionary: data.options.onlineDictionary ?? true,
            language: data.options.language ?? "auto",

            // Readable Experience
            readableExperience: data.options.readableExperience ?? true,
            contentScaling: data.options.contentScaling ?? true,
            textMagnifier: data.options.textMagnifier ?? true,
            readableFont: data.options.readableFont ?? true,
            dyslexiaFont: data.options.dyslexiaFont ?? true,
            highlightTitles: data.options.highlightTitles ?? true,
            highlightLinks: data.options.highlightLinks ?? true,
            fontSizing: data.options.fontSizing ?? true,
            lineHeight: data.options.lineHeight ?? true,
            letterSpacing: data.options.letterSpacing ?? true,
            alignCenter: data.options.alignCenter ?? true,
            alignLeft: data.options.alignLeft ?? true,
            alignRight: data.options.alignRight ?? true,

            // Visually Pleasing Experience
            visuallyPleasingExperience:
              data.options.visuallyPleasingExperience ?? true,
            darkContrast: data.options.darkContrast ?? true,
            lightContrast: data.options.lightContrast ?? true,
            monochrome: data.options.monochrome ?? true,
            highSaturation: data.options.highSaturation ?? true,
            highContrast: data.options.highContrast ?? true,
            lowSaturation: data.options.lowSaturation ?? true,
            textColors: data.options.textColors ?? true,
            titleColors: data.options.titleColors ?? true,
            backgroundColors: data.options.backgroundColors ?? true,

            // Easy Orientation
            easyOrientation: data.options.easyOrientation ?? true,
            muteSounds: data.options.muteSounds ?? true,
            hideImages: data.options.hideImages ?? true,
            virtualKeyboard: data.options.virtualKeyboard ?? true,
            readingGuide: data.options.readingGuide ?? true,
            usefulLinks: data.options.usefulLinks ?? true,
            stopAnimations: data.options.stopAnimations ?? true,
            readingMask: data.options.readingMask ?? true,
            highlightHover: data.options.highlightHover ?? true,
            highlightFocus: data.options.highlightFocus ?? true,
            bigBlackCursor: data.options.bigBlackCursor ?? true,
            bigWhiteCursor: data.options.bigWhiteCursor ?? true,
            textToSpeech: data.options.textToSpeech ?? true,
            keyboardNavigation: data.options.keyboardNavigation ?? true,

            // Reading Mask
            readingMaskHeight: data.options.readingMaskHeight ?? 100,

            // Virtual Keyboard
            virtualKeyboardLayout:
              data.options.virtualKeyboardLayout ?? "english",

            // Text to Speech
            ttsPlayIcon: data.options.ttsPlayIcon ?? defaultOptions.ttsPlayIcon,
            ttsStopIcon: data.options.ttsStopIcon ?? defaultOptions.ttsStopIcon,
            ttsIconSize: data.options.ttsIconSize ?? 32,
            ttsLang: data.options.ttsLang ?? "",
            ttsPitch: data.options.ttsPitch ?? 1,
            ttsRate: data.options.ttsRate ?? 1,
            ttsVoiceName: data.options.ttsVoiceName ?? "",
            ttsVolume: data.options.ttsVolume ?? 1,

            // Footer Options
            resetButton: data.options.resetButton ?? true,
            hideButton: data.options.hideButton ?? true,
            accessibilityStatement: data.options.accessibilityStatement ?? true,
            accessibilityStatementType:
              data.options.accessibilityStatementType ?? "iframe",
            accessibilityStatementLink:
              data.options.accessibilityStatementLink ??
              "./readabler/accessibility-statement.html",
            accessibilityStatementHtml:
              data.options.accessibilityStatementHtml ?? "",

            // Hot Keys options
            hotKeyOpenInterface: data.options.hotKeyOpenInterface ?? "Alt+9",
            hotKeyMenu: data.options.hotKeyMenu ?? "M",
            hotKeyHeadings: data.options.hotKeyHeadings ?? "H",
            hotKeyForms: data.options.hotKeyForms ?? "F",
            hotKeyButtons: data.options.hotKeyButtons ?? "B",
            hotKeyGraphics: data.options.hotKeyGraphics ?? "G",
          });
        }
      })
      .catch(() => {});
  }

  // Add an index signature to defaultOptions
  const defaultOptions: { [key: string]: any } = {
    path: "./readabler",
    locale: "",
    supportedLanguages: ["en", "uk"] /** NEW */,
    // 'palette'                       : _palette__WEBPACK_IMPORTED_MODULE_0__.palette,
    headingTag: "h3",
    subHeadingTag: "h4",

    // Templates
    template: "popup" /** NEW */, // popup, off-canvas, aside
    sidebarAlign: "left" /** NEW */,

    // Open Button
    showOpenButton: true,
    buttonTabulationIndex: 0,
    buttonPosition: "bottom-right",
    buttonCaption: "",
    buttonIcon:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.05 349.63"><path d="M95.37,51.29a51.23,51.23,0,1,1,51.29,51.16h-.07A51.19,51.19,0,0,1,95.37,51.29ZM293,134.59A25.61,25.61,0,0,0,265.49,111h-.13l-89.64,8c-3.06.28-6.13.42-9.19.42H126.65q-4.59,0-9.16-.41L27.7,111a25.58,25.58,0,0,0-4.23,51l.22,0,72.45,6.56a8.55,8.55,0,0,1,7.77,8.48v19.62a33.82,33.82,0,0,1-2.36,12.45L60.48,313.66a25.61,25.61,0,1,0,46.85,20.71h0l39.14-95.61L186,334.63A25.61,25.61,0,0,0,232.86,314L191.63,209.14a34.14,34.14,0,0,1-2.35-12.44V177.09a8.55,8.55,0,0,1,7.77-8.49l72.33-6.55A25.61,25.61,0,0,0,293,134.59Z"/></svg>',
    buttonIconPosition: "before",
    buttonEntranceAnimation: "fade",
    buttonHoverAnimation: "none",

    // Popup
    popupOverlay: true,
    popupAnimation: "fade",
    popupScroll: false,
    closeAnywhere: true,
    closeButton: true,
    popupDraggable: true,
    popupShadow: true /** NEW */,

    // Accessibility Profiles
    accessibilityProfiles: true,
    profileEpilepsy: true,
    profileVisuallyImpaired: true,
    profileCognitiveDisability: true,
    profileAdhdFriendly: true,
    profileBlindUsers: true,

    // Online Dictionary
    onlineDictionary: true,
    language: "auto",

    // Readable Experience
    readableExperience: true,
    contentScaling: true,
    textMagnifier: true,
    readableFont: true,
    dyslexiaFont: true,
    highlightTitles: true,
    highlightLinks: true,
    fontSizing: true,
    lineHeight: true,
    letterSpacing: true,
    alignCenter: true,
    alignLeft: true,
    alignRight: true,

    // Visually Pleasing Experience
    visuallyPleasingExperience: true,
    darkContrast: true,
    lightContrast: true,
    monochrome: true,
    highSaturation: true,
    highContrast: true,
    lowSaturation: true,
    textColors: true,
    titleColors: true,
    backgroundColors: true,

    // Easy Orientation
    easyOrientation: true,
    muteSounds: true,
    hideImages: true,
    virtualKeyboard: true,
    readingGuide: true,
    usefulLinks: true,
    stopAnimations: true,
    readingMask: true,
    highlightHover: true,
    highlightFocus: true,
    bigBlackCursor: true,
    bigWhiteCursor: true,
    textToSpeech: true,
    keyboardNavigation: true,

    // Footer
    resetButton: true,
    hideButton: true,
    accessibilityStatement: true,
    accessibilityStatementType: "iframe", // iframe, html, link
    accessibilityStatementLink: "./readabler/accessibility-statement.html",
    accessibilityStatementHtml: "",

    // Reading Mask
    readingMaskHeight: 100,

    // Virtual Keyboard
    virtualKeyboardLayout: "english",

    // Hot Keys
    hotKeyOpenInterface: "Alt+9",
    hotKeyMenu: "M",
    hotKeyHeadings: "H",
    hotKeyForms: "F",
    hotKeyButtons: "B",
    hotKeyGraphics: "G",

    // Text to Speech
    ttsPlayIcon:
      '<svg class="selection__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M19.3,10.7L9.1,4.2C8.8,4,8.4,4,8.1,4C7,4,7,4.9,7,5.1v13.4c0,0.2,0,1.1,1.1,1.1c0.3,0,0.7,0,1-0.2l10.2-6.5c0.8-0.5,0.7-1.1,0.7-1.1S20.1,11.2,19.3,10.7z"/></svg>',
    ttsStopIcon:
      '<svg class="selection__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M18.001 4.2H6A1.8 1.8 0 0 0 4.2 5.999V18A1.8 1.8 0 0 0 5.999 19.8H18a1.8 1.8 0 0 0 1.799-1.799V6c0-.992-.807-1.799-1.799-1.799z"/></svg>',
    ttsIconSize: 32,
    ttsLang: "",
    ttsPitch: 1, // 0 - 2 (float)
    ttsRate: 1, // 0.1 - 10 (float)
    ttsVoiceName: "",
    ttsVolume: 1, // 0 - 1 (float)
  };

  const cleanOptions = (options: any) => {
    console.log(options);

    const cleaned: any = {};

    Object.entries(options).forEach(([key, value]) => {
      // If the value is different from default, include it
      if (
        JSON.stringify((defaultOptions as any)[key]) !== JSON.stringify(value)
      ) {
        cleaned[key] = value;
      }
    });

    console.log(cleaned);

    return cleaned;
  };

  // Update the save function to use cleanOptions
  const saveSettings = () => {
    setIsSaving(true);

    const cleanedOptions = cleanOptions(options);

    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: instance || "",
      },
      body: JSON.stringify({
        options: cleanedOptions,
        isEnabled
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsSaving(false);
        showNotification({
          title: "Settings saved successfully",
          type: "success",
        });
      })
      .catch(() => {
        setIsSaving(false);
        showNotification({
          title: "Failed to save settings",
          type: "error",
        });
      });
  };

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=865ea807-f2a8-4aa2-9df8-94aee1168023&redirectUrl=https://certifiedcode.wixsite.com/readable/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading..." />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"865ea807-f2a8-4aa2-9df8-94aee1168023"}?appInstanceId=${
    (instanceData as any)?.instance?.instanceId
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          Upgrade to Premium to unlock all features
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade Now
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title="Readable: WCAG Accessibility"
          subtitle="Your site, their experience"
          actionsBar={
            <Box direction="horizontal" gap="SP2">
              <Button
                skin={isUpgraded ? "premium-light" : "premium"}
                as="a"
                href={upgradeUrl}
                target="_blank"
                prefixIcon={<Icons.PremiumFilled />}
              >
                {isUpgraded ? "Manage Plan" : "Upgrade to Premium"}
              </Button>
              {isUpgraded && (
                <Button
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/865ea807-f2a8-4aa2-9df8-94aee1168023"
                  target="_blank"
                >
                  Rate Us
                </Button>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title="Enable Readable?"
                  suffix={
                    <ToggleSwitch
                      checked={isEnabled}
                      onChange={() => setIsEnabled(!isEnabled)}
                    />
                  }
                />
              </Card>
            </Cell>
            <Cell span={3}>
              <Box height="600px" width="100%">
                <SidebarNext
                  isLoading={false}
                  selectedKey={selectedSection}
                  skin="light"
                >
                  <SidebarItemNext
                    itemKey="accessibility"
                    onClick={() => setSelectedSection("accessibility")}
                  >
                    Accessibility Profiles
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="dictionary"
                    onClick={() => setSelectedSection("dictionary")}
                  >
                    Online Dictionary
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="readable"
                    onClick={() => setSelectedSection("readable")}
                  >
                    Readable Experience
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="visual"
                    onClick={() => setSelectedSection("visual")}
                  >
                    Visually Pleasing
                  </SidebarItemNext>
                  <SidebarDividerNext />
                  <SidebarItemNext
                    itemKey="orientation"
                    onClick={() => setSelectedSection("orientation")}
                  >
                    Easy Orientation
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="reading-mask"
                    onClick={() => setSelectedSection("reading-mask")}
                  >
                    Reading Mask
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="virtual-keyboard"
                    onClick={() => setSelectedSection("virtual-keyboard")}
                  >
                    Virtual Keyboard
                  </SidebarItemNext>
                  <SidebarDividerNext />
                  <SidebarItemNext
                    itemKey="speech"
                    onClick={() => setSelectedSection("speech")}
                  >
                    Speech Synthesis
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="footer"
                    onClick={() => setSelectedSection("footer")}
                  >
                    Footer Options
                  </SidebarItemNext>
                  <SidebarItemNext
                    itemKey="hotkeys"
                    onClick={() => setSelectedSection("hotkeys")}
                  >
                    Hot Keys
                  </SidebarItemNext>
                  <SidebarItemNext disabled={true}>
                    Changes save automatically
                  </SidebarItemNext>
                </SidebarNext>
              </Box>
            </Cell>
            <Cell span={9}>
              <Card>
                <Card.Content>
                  {selectedSection === "accessibility" && (
                    <Layout>
                      <Cell span={6}>
                        <FormField label="Enable Accessibility Profiles">
                          <ToggleSwitch
                            checked={options.accessibilityProfiles}
                            onChange={() =>
                              setOptions({
                                ...options,
                                accessibilityProfiles:
                                  !options.accessibilityProfiles,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Epilepsy Safe Mode"
                          infoContent="Enables people with epilepsy to use the website safely by eliminating the risk of seizures"
                        >
                          <ToggleSwitch
                            checked={options.profileEpilepsy}
                            onChange={() =>
                              setOptions({
                                ...options,
                                profileEpilepsy: !options.profileEpilepsy,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Visually Impaired Mode"
                          infoContent="Adjusts the website for users with visual impairments such as Degrading Eyesight, Tunnel Vision, Cataract, Glaucoma"
                        >
                          <ToggleSwitch
                            checked={options.profileVisuallyImpaired}
                            onChange={() =>
                              setOptions({
                                ...options,
                                profileVisuallyImpaired:
                                  !options.profileVisuallyImpaired,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Cognitive Disability Mode"
                          infoContent="Helps users with cognitive impairments such as Dyslexia, Autism, CVA focus on essential elements"
                        >
                          <ToggleSwitch
                            checked={options.profileCognitiveDisability}
                            onChange={() =>
                              setOptions({
                                ...options,
                                profileCognitiveDisability:
                                  !options.profileCognitiveDisability,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="ADHD Friendly Mode"
                          infoContent="Helps users with ADHD and Neurodevelopmental disorders to read and focus while reducing distractions"
                        >
                          <ToggleSwitch
                            checked={options.profileAdhdFriendly}
                            onChange={() =>
                              setOptions({
                                ...options,
                                profileAdhdFriendly:
                                  !options.profileAdhdFriendly,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Blindness Mode"
                          infoContent="Configures the website to be compatible with screen-readers like JAWS, NVDA, VoiceOver, and TalkBack"
                        >
                          <ToggleSwitch
                            checked={options.profileBlindUsers}
                            onChange={() =>
                              setOptions({
                                ...options,
                                profileBlindUsers: !options.profileBlindUsers,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "dictionary" && (
                    <Layout>
                      <Cell span={6}>
                        <FormField
                          label="Enable Online Dictionary"
                          infoContent="Adds a search field to the Accessibility window settings that allows searching for phrases, abbreviations and concepts"
                        >
                          <ToggleSwitch
                            checked={options.onlineDictionary}
                            onChange={() =>
                              setOptions({
                                ...options,
                                onlineDictionary: !options.onlineDictionary,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Dictionary Language"
                          infoContent="Specify your site language to use the dictionary in the appropriate language"
                        >
                          <Dropdown
                            placeholder="Select language"
                            value={options.language}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                language: event.target.value,
                              })
                            }
                            options={[
                              {
                                id: "auto",
                                value: "auto",
                                label: "Auto Detect",
                              },
                              { id: "ar", value: "ar", label: "Arabic" },
                              { id: "zh", value: "zh", label: "Chinese" },
                              { id: "en", value: "en", label: "English" },
                              { id: "fr", value: "fr", label: "French" },
                              { id: "de", value: "de", label: "German" },
                              { id: "hi", value: "hi", label: "Hindi" },
                              { id: "it", value: "it", label: "Italian" },
                              { id: "ja", value: "ja", label: "Japanese" },
                              { id: "ko", value: "ko", label: "Korean" },
                              { id: "pt", value: "pt", label: "Portuguese" },
                              { id: "ru", value: "ru", label: "Russian" },
                              { id: "es", value: "es", label: "Spanish" },
                              // Additional languages can be added from the supported list:
                              // ast, az, bg, nan, bn, be, ca, cs, cy, da, et, el, eo, eu,
                              // fa, gl, hy, hr, id, he, ka, la, lv, lt, hu, mk, arz, ms,
                              // min, my, nl, nb, nn, ce, uz, pl, kk, ro, ceb, sk, sl, sr,
                              // sh, fi, sv, ta, tt, th, tg, azb, tr, uk, ur, vi, vo, war, yue
                            ]}
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "readable" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Enable Readable Experience"
                          infoContent="Enable/disable block settings of the Readable Experience"
                        >
                          <ToggleSwitch
                            checked={options.readableExperience}
                            onChange={() =>
                              setOptions({
                                ...options,
                                readableExperience: !options.readableExperience,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Content Scaling"
                          infoContent="Enable scaling the website content as a percentage"
                        >
                          <ToggleSwitch
                            checked={options.contentScaling}
                            onChange={() =>
                              setOptions({
                                ...options,
                                contentScaling: !options.contentScaling,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Text Magnifier"
                          infoContent="Display specific text in the magnifier on hover"
                        >
                          <ToggleSwitch
                            checked={options.textMagnifier}
                            onChange={() =>
                              setOptions({
                                ...options,
                                textMagnifier: !options.textMagnifier,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Readable Font"
                          infoContent="Automatically convert the text font to a more readable one"
                        >
                          <ToggleSwitch
                            checked={options.readableFont}
                            onChange={() =>
                              setOptions({
                                ...options,
                                readableFont: !options.readableFont,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Dyslexia Font"
                          infoContent="Adapt the font to be more convenient for Dyslexic users"
                        >
                          <ToggleSwitch
                            checked={options.dyslexiaFont}
                            onChange={() =>
                              setOptions({
                                ...options,
                                dyslexiaFont: !options.dyslexiaFont,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Highlight Titles"
                          infoContent="Highlight the titles with borders for all site content"
                        >
                          <ToggleSwitch
                            checked={options.highlightTitles}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highlightTitles: !options.highlightTitles,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Highlight Links"
                          infoContent="Highlight the links with borders for all site content"
                        >
                          <ToggleSwitch
                            checked={options.highlightLinks}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highlightLinks: !options.highlightLinks,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Font Sizing"
                          infoContent="Change the text font size as a percentage"
                        >
                          <ToggleSwitch
                            checked={options.fontSizing}
                            onChange={() =>
                              setOptions({
                                ...options,
                                fontSizing: !options.fontSizing,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Line Height"
                          infoContent="Change the font line-height as a percentage"
                        >
                          <ToggleSwitch
                            checked={options.lineHeight}
                            onChange={() =>
                              setOptions({
                                ...options,
                                lineHeight: !options.lineHeight,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Letter Spacing"
                          infoContent="Change the text letter spacing as a percentage"
                        >
                          <ToggleSwitch
                            checked={options.letterSpacing}
                            onChange={() =>
                              setOptions({
                                ...options,
                                letterSpacing: !options.letterSpacing,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Align Center"
                          infoContent="Align the website content to the center"
                        >
                          <ToggleSwitch
                            checked={options.alignCenter}
                            onChange={() =>
                              setOptions({
                                ...options,
                                alignCenter: !options.alignCenter,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Align Left"
                          infoContent="Align the website content to the left"
                        >
                          <ToggleSwitch
                            checked={options.alignLeft}
                            onChange={() =>
                              setOptions({
                                ...options,
                                alignLeft: !options.alignLeft,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Align Right"
                          infoContent="Align the website content to the right"
                        >
                          <ToggleSwitch
                            checked={options.alignRight}
                            onChange={() =>
                              setOptions({
                                ...options,
                                alignRight: !options.alignRight,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "visual" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Enable Visually Pleasing Experience"
                          infoContent="Enable/disable block settings of the Visually Pleasing Experience"
                        >
                          <ToggleSwitch
                            checked={options.visuallyPleasingExperience}
                            onChange={() =>
                              setOptions({
                                ...options,
                                visuallyPleasingExperience:
                                  !options.visuallyPleasingExperience,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Dark Contrast"
                          infoContent="Set dark contrast for the content"
                        >
                          <ToggleSwitch
                            checked={options.darkContrast}
                            onChange={() =>
                              setOptions({
                                ...options,
                                darkContrast: !options.darkContrast,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Light Contrast"
                          infoContent="Set light contrast for the content"
                        >
                          <ToggleSwitch
                            checked={options.lightContrast}
                            onChange={() =>
                              setOptions({
                                ...options,
                                lightContrast: !options.lightContrast,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Monochrome"
                          infoContent="Apply a monochrome color scheme for the content"
                        >
                          <ToggleSwitch
                            checked={options.monochrome}
                            onChange={() =>
                              setOptions({
                                ...options,
                                monochrome: !options.monochrome,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="High Saturation"
                          infoContent="Add color saturation for the site content"
                        >
                          <ToggleSwitch
                            checked={options.highSaturation}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highSaturation: !options.highSaturation,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="High Contrast"
                          infoContent="Increase color contrast for the site content"
                        >
                          <ToggleSwitch
                            checked={options.highContrast}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highContrast: !options.highContrast,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Low Saturation"
                          infoContent="Minimize color saturation for the site content"
                        >
                          <ToggleSwitch
                            checked={options.lowSaturation}
                            onChange={() =>
                              setOptions({
                                ...options,
                                lowSaturation: !options.lowSaturation,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Text Colors"
                          infoContent="Display a color picker and adjust the text color of website content"
                        >
                          <ToggleSwitch
                            checked={options.textColors}
                            onChange={() =>
                              setOptions({
                                ...options,
                                textColors: !options.textColors,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Title Colors"
                          infoContent="Display a color picker and adjust the titles color of website content"
                        >
                          <ToggleSwitch
                            checked={options.titleColors}
                            onChange={() =>
                              setOptions({
                                ...options,
                                titleColors: !options.titleColors,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Background Colors"
                          infoContent="Display a color picker and adjust the background color of website"
                        >
                          <ToggleSwitch
                            checked={options.backgroundColors}
                            onChange={() =>
                              setOptions({
                                ...options,
                                backgroundColors: !options.backgroundColors,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "orientation" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Enable Easy Orientation"
                          infoContent="Enable/disable block settings of the Easy Orientation"
                        >
                          <ToggleSwitch
                            checked={options.easyOrientation}
                            onChange={() =>
                              setOptions({
                                ...options,
                                easyOrientation: !options.easyOrientation,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Mute Sounds"
                          infoContent="Mute all sounds on the page"
                        >
                          <ToggleSwitch
                            checked={options.muteSounds}
                            onChange={() =>
                              setOptions({
                                ...options,
                                muteSounds: !options.muteSounds,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Hide Images"
                          infoContent="Hide all images on the page"
                        >
                          <ToggleSwitch
                            checked={options.hideImages}
                            onChange={() =>
                              setOptions({
                                ...options,
                                hideImages: !options.hideImages,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Virtual Keyboard"
                          infoContent="Enable the virtual keyboard when any input field is active"
                        >
                          <ToggleSwitch
                            checked={options.virtualKeyboard}
                            onChange={() =>
                              setOptions({
                                ...options,
                                virtualKeyboard: !options.virtualKeyboard,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Reading Guide"
                          infoContent="Display a guideline that follows the cursor and helps to concentrate only on the specific text"
                        >
                          <ToggleSwitch
                            checked={options.readingGuide}
                            onChange={() =>
                              setOptions({
                                ...options,
                                readingGuide: !options.readingGuide,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Useful Links"
                          infoContent="Display a select list of useful site links to get the necessary info without site research"
                        >
                          <ToggleSwitch
                            checked={options.usefulLinks}
                            onChange={() =>
                              setOptions({
                                ...options,
                                usefulLinks: !options.usefulLinks,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Stop Animations"
                          infoContent="Disable animations on the page"
                        >
                          <ToggleSwitch
                            checked={options.stopAnimations}
                            onChange={() =>
                              setOptions({
                                ...options,
                                stopAnimations: !options.stopAnimations,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Reading Mask"
                          infoContent="Create a horizontal mask that follows the cursor and allows you to focus on specific content"
                        >
                          <ToggleSwitch
                            checked={options.readingMask}
                            onChange={() =>
                              setOptions({
                                ...options,
                                readingMask: !options.readingMask,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Reading Mask Height"
                          infoContent="Specify the height of the Reading Mask in pixels"
                        >
                          <Input
                            type="number"
                            value={options.readingMaskHeight}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                readingMaskHeight:
                                  parseInt(event.target.value) || 100,
                              })
                            }
                            min={1}
                            max={1000}
                          />
                        </FormField>
                        <FormField
                          label="Highlight Hover"
                          infoContent="Highlight the hover area by borders"
                        >
                          <ToggleSwitch
                            checked={options.highlightHover}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highlightHover: !options.highlightHover,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Highlight Focus"
                          infoContent="Highlight the focus area by borders"
                        >
                          <ToggleSwitch
                            checked={options.highlightFocus}
                            onChange={() =>
                              setOptions({
                                ...options,
                                highlightFocus: !options.highlightFocus,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Big Black Cursor"
                          infoContent="Enable a large black cursor instead of the regular one"
                        >
                          <ToggleSwitch
                            checked={options.bigBlackCursor}
                            onChange={() =>
                              setOptions({
                                ...options,
                                bigBlackCursor: !options.bigBlackCursor,
                              })
                            }
                          />
                        </FormField>
                        <FormField
                          label="Big White Cursor"
                          infoContent="Enable a large white cursor instead of the regular one"
                        >
                          <ToggleSwitch
                            checked={options.bigWhiteCursor}
                            onChange={() =>
                              setOptions({
                                ...options,
                                bigWhiteCursor: !options.bigWhiteCursor,
                              })
                            }
                          />
                        </FormField>
                      </Cell>

                      <Cell span={12}>
                        <Box direction="vertical" gap="SP3">
                          <FormField
                            label="Text to Speech"
                            infoContent="Enable text to speech feature based on Web Speech API (Not supported in Safari)"
                          >
                            <ToggleSwitch
                              checked={options.textToSpeech}
                              onChange={() =>
                                setOptions({
                                  ...options,
                                  textToSpeech: !options.textToSpeech,
                                })
                              }
                            />
                          </FormField>
                        </Box>
                      </Cell>

                      <Cell span={6}>
                        <FormField
                          label="Keyboard Navigation"
                          infoContent="Enable keyboard navigation feature"
                        >
                          <ToggleSwitch
                            checked={options.keyboardNavigation}
                            onChange={() =>
                              setOptions({
                                ...options,
                                keyboardNavigation: !options.keyboardNavigation,
                              })
                            }
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "reading-mask" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Reading Mask Height"
                          infoContent="Specify the height of the Reading Mask in pixels"
                        >
                          <Input
                            type="number"
                            value={options.readingMaskHeight}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                readingMaskHeight:
                                  parseInt(event.target.value) || 100,
                              })
                            }
                            min={1}
                            max={1000}
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "virtual-keyboard" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Virtual Keyboard Layout"
                          infoContent="Specify a language layout for the virtual keyboard"
                        >
                          <Dropdown
                            placeholder="Select keyboard layout"
                            value={options.virtualKeyboardLayout}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                virtualKeyboardLayout: event.target.value,
                              })
                            }
                            options={[
                              {
                                id: "arabic",
                                value: "arabic",
                                label: "Arabic",
                              },
                              {
                                id: "assamese",
                                value: "assamese",
                                label: "Assamese",
                              },
                              {
                                id: "belarusian",
                                value: "belarusian",
                                label: "Belarusian",
                              },
                              {
                                id: "bengali",
                                value: "bengali",
                                label: "Bengali",
                              },
                              {
                                id: "burmese",
                                value: "burmese",
                                label: "Burmese",
                              },
                              {
                                id: "chinese",
                                value: "chinese",
                                label: "Chinese",
                              },
                              { id: "czech", value: "czech", label: "Czech" },
                              {
                                id: "english",
                                value: "english",
                                label: "English",
                              },
                              { id: "farsi", value: "farsi", label: "Farsi" },
                              {
                                id: "french",
                                value: "french",
                                label: "French",
                              },
                              {
                                id: "georgian",
                                value: "georgian",
                                label: "Georgian",
                              },
                              {
                                id: "german",
                                value: "german",
                                label: "German",
                              },
                              {
                                id: "gilaki",
                                value: "gilaki",
                                label: "Gilaki",
                              },
                              { id: "greek", value: "greek", label: "Greek" },
                              {
                                id: "hebrew",
                                value: "hebrew",
                                label: "Hebrew",
                              },
                              { id: "hindi", value: "hindi", label: "Hindi" },
                              {
                                id: "italian",
                                value: "italian",
                                label: "Italian",
                              },
                              {
                                id: "japanese",
                                value: "japanese",
                                label: "Japanese",
                              },
                              {
                                id: "kannada",
                                value: "kannada",
                                label: "Kannada",
                              },
                              {
                                id: "korean",
                                value: "korean",
                                label: "Korean",
                              },
                              {
                                id: "nigerian",
                                value: "nigerian",
                                label: "Nigerian",
                              },
                              {
                                id: "norwegian",
                                value: "norwegian",
                                label: "Norwegian",
                              },
                              {
                                id: "polish",
                                value: "polish",
                                label: "Polish",
                              },
                              {
                                id: "russian",
                                value: "russian",
                                label: "Russian",
                              },
                              {
                                id: "sindhi",
                                value: "sindhi",
                                label: "Sindhi",
                              },
                              {
                                id: "spanish",
                                value: "spanish",
                                label: "Spanish",
                              },
                              {
                                id: "swedish",
                                value: "swedish",
                                label: "Swedish",
                              },
                              { id: "thai", value: "thai", label: "Thai" },
                              {
                                id: "turkish",
                                value: "turkish",
                                label: "Turkish",
                              },
                              {
                                id: "ukrainian",
                                value: "ukrainian",
                                label: "Ukrainian",
                              },
                              { id: "urdu", value: "urdu", label: "Urdu" },
                              {
                                id: "uyghur",
                                value: "uyghur",
                                label: "Uyghur",
                              },
                            ]}
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "speech" && (
                    <Layout>
                      <Cell span={12}>
                        <FormField
                          label="Speech Language"
                          infoContent="The speech recognition language"
                        >
                          <Dropdown
                            placeholder="Select speech language"
                            value={options.ttsLang}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                ttsLang: event.target.value,
                              })
                            }
                            options={[
                              {
                                id: "en-US",
                                value: "en-US",
                                label: "English (US)",
                              },
                              {
                                id: "en-GB",
                                value: "en-GB",
                                label: "English (UK)",
                              },
                              { id: "es-ES", value: "es-ES", label: "Spanish" },
                              { id: "fr-FR", value: "fr-FR", label: "French" },
                              { id: "de-DE", value: "de-DE", label: "German" },
                              // Add more language options as needed
                            ]}
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Speech Pitch"
                          infoContent="Speaking pitch, in the range [0 – 2]. Higher values increase the pitch."
                        >
                          <Input
                            type="number"
                            value={options.ttsPitch}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                ttsPitch: parseFloat(event.target.value) || 1,
                              })
                            }
                            min={0}
                            max={2}
                            step={0.1}
                          />
                        </FormField>
                      </Cell>
                      <Cell span={6}>
                        <FormField
                          label="Speech Rate"
                          infoContent="Voice playback speed (0.1 to 10). Normal speed is 1."
                        >
                          <Input
                            type="number"
                            value={options.ttsRate}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                ttsRate: parseFloat(event.target.value) || 1,
                              })
                            }
                            min={0.1}
                            max={10}
                            step={0.1}
                          />
                        </FormField>
                      </Cell>
                      <Cell span={12}>
                        <FormField
                          label="Speech Volume"
                          infoContent="Voice volume (0 to 1)"
                        >
                          <Input
                            type="number"
                            value={options.ttsVolume}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                ttsVolume: parseFloat(event.target.value) || 1,
                              })
                            }
                            min={0}
                            max={1}
                            step={0.1}
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "footer" && (
                    <Layout>
                      <Cell span={6}>
                        <FormField
                          label="Reset Button"
                          infoContent="Enable/disable the Reset button in the Accessibility popup window settings"
                        >
                          <ToggleSwitch
                            checked={options.resetButton}
                            onChange={() =>
                              setOptions({
                                ...options,
                                resetButton: !options.resetButton,
                              })
                            }
                          />
                        </FormField>

                        <FormField
                          label="Hide Button"
                          infoContent="Enable/disable the Hide button in the Accessibility popup window settings"
                        >
                          <ToggleSwitch
                            checked={options.hideButton}
                            onChange={() =>
                              setOptions({
                                ...options,
                                hideButton: !options.hideButton,
                              })
                            }
                          />
                        </FormField>

                        {/* <FormField 
                      label="Powered By"
                      infoContent="Show powered by Readabler link"
                    >
                      <ToggleSwitch
                        checked={options.poweredBy}
                        onChange={() =>
                          setOptions({
                            ...options,
                            poweredBy: !options.poweredBy,
                          })
                        }
                      />
                    </FormField> */}
                      </Cell>

                      <Cell span={6}>
                        <FormField
                          label="Accessibility Statement"
                          infoContent="Enable/disable the Accessibility Statement link in the popup window settings"
                        >
                          <ToggleSwitch
                            checked={options.accessibilityStatement}
                            onChange={() =>
                              setOptions({
                                ...options,
                                accessibilityStatement:
                                  !options.accessibilityStatement,
                              })
                            }
                          />
                        </FormField>

                        {options.accessibilityStatement && (
                          <>
                            <FormField
                              label="Statement Type"
                              infoContent="Specify the type of Accessibility Statement"
                            >
                              <Dropdown
                                placeholder="Select statement type"
                                value={options.accessibilityStatementType}
                                onChange={(event) =>
                                  setOptions({
                                    ...options,
                                    accessibilityStatementType:
                                      event.target.value,
                                  })
                                }
                                options={[
                                  {
                                    id: "iframe",
                                    value: "iframe",
                                    label: "IFrame",
                                  },
                                  { id: "html", value: "html", label: "HTML" },
                                  { id: "link", value: "link", label: "Link" },
                                ]}
                              />
                            </FormField>

                            {(options.accessibilityStatementType === "iframe" ||
                              options.accessibilityStatementType ===
                                "link") && (
                              <FormField
                                label="Statement Link"
                                infoContent="Specify the Accessibility Statement link"
                              >
                                <Input
                                  value={options.accessibilityStatementLink}
                                  onChange={(event) =>
                                    setOptions({
                                      ...options,
                                      accessibilityStatementLink:
                                        event.target.value,
                                    })
                                  }
                                  placeholder="Enter URL"
                                />
                              </FormField>
                            )}

                            {options.accessibilityStatementType === "html" && (
                              <FormField
                                label="Statement HTML"
                                infoContent="Specify the Accessibility Statement HTML"
                              >
                                <Input
                                  type="textarea"
                                  value={options.accessibilityStatementHtml}
                                  onChange={(event) =>
                                    setOptions({
                                      ...options,
                                      accessibilityStatementHtml:
                                        event.target.value,
                                    })
                                  }
                                  placeholder="Enter HTML content"
                                />
                              </FormField>
                            )}
                          </>
                        )}
                      </Cell>
                    </Layout>
                  )}

                  {selectedSection === "hotkeys" && (
                    <Layout>
                      <Cell span={6}>
                        <FormField
                          label="Open Interface"
                          infoContent="Opens the Accessibility Interface"
                        >
                          <Input
                            value={options.hotKeyOpenInterface}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyOpenInterface: event.target.value,
                              })
                            }
                            placeholder="Alt+9"
                          />
                        </FormField>

                        <FormField
                          label="Menu Navigation"
                          infoContent="The default hotkey for menus navigation"
                        >
                          <Input
                            value={options.hotKeyMenu}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyMenu: event.target.value,
                              })
                            }
                            placeholder="M"
                          />
                        </FormField>

                        <FormField
                          label="Headings Navigation"
                          infoContent="The default hotkey for headings navigation"
                        >
                          <Input
                            value={options.hotKeyHeadings}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyHeadings: event.target.value,
                              })
                            }
                            placeholder="H"
                          />
                        </FormField>
                      </Cell>

                      <Cell span={6}>
                        <FormField
                          label="Forms Navigation"
                          infoContent="The default hotkey for forms navigation"
                        >
                          <Input
                            value={options.hotKeyForms}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyForms: event.target.value,
                              })
                            }
                            placeholder="F"
                          />
                        </FormField>

                        <FormField
                          label="Buttons Navigation"
                          infoContent="The default hotkey for buttons navigation"
                        >
                          <Input
                            value={options.hotKeyButtons}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyButtons: event.target.value,
                              })
                            }
                            placeholder="B"
                          />
                        </FormField>

                        <FormField
                          label="Graphics Navigation"
                          infoContent="The default hotkey for graphics navigation"
                        >
                          <Input
                            value={options.hotKeyGraphics}
                            onChange={(event) =>
                              setOptions({
                                ...options,
                                hotKeyGraphics: event.target.value,
                              })
                            }
                            placeholder="G"
                          />
                        </FormField>
                      </Cell>
                    </Layout>
                  )}
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
